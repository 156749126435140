import React from 'react';

import Signup from '../Components/Signup/Signup';
import Header2 from '../Components/Header2/Header2';

function SignupPage() {
  return (
    <div>
      <Header2 />
      <Signup />
      
    </div>
  );
}

export default SignupPage;
