import React from 'react';


import './CE.css';
import {useHistory} from 'react-router-dom'

function IT() {
  const history = useHistory()
  return (
    <div className="postParentDiv">
      <div>

      </div>
        
      <div className="recommendations">
        <div className="heading">
          <span>Computer Engineering</span>
        </div>
        </div>
            
        </div>
    
      
  );
}

export default IT;
